
// 项目基本
const state = {
  isCollapse: false, // 菜单关闭/展开
  AgentId: "1666059590",
  AppKey: "dingkz9ksda059v841z5",
  AppSecret: "MfmdZ17Nmz-4mLD_D_Q3MyFNig45qaXEa-L3hLXjo67B_WeXnGeFaft7bEwgmsPb",
  Access_token: "",
  redirectUri: encodeURIComponent('https://formal.system.manage.tianjinlianxin.com'), // 开发
}

const mutations = {
  // 切换菜单开启/关闭状态
  CHANGE_MENV_STATUS: state => {
    state.isCollapse = !state.isCollapse;
  },
  // 设置登录令牌
  SET_ACCESS_TOKEN: (state, param) => {
    state.Access_token = param;
  }
}

export default {
  state,
  mutations
}